import firebase from "firebase/app";
// import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyALxu3TLoNsleL13Z7ifegggF2Z1TXWOKo",
  authDomain: "showroom-app-9612d.firebaseapp.com",
  databaseURL: "https://showroom-app-9612d-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "showroom-app-9612d",
  storageBucket: "showroom-app-9612d.appspot.com",
  messagingSenderId: "991742940766",
  appId: "1:991742940766:web:81ba854f30621630dfc6cf",
  measurementId: "G-LF6FK6Y14Y"
};
firebase.initializeApp(firebaseConfig);

const db = firebase.database();
var currentPage = {};

// Page renderer
function renderPage(pageID) {
  if (!pageID) return;
  db.ref("SlideShow/currentPage").update({ pageID: pageID,time : Date.now() });
}
export { db, renderPage, currentPage };
